<template>
  <div class="add_user">
    <div class="tip">{{ $t("personal.userManagement.newUserForm.info1") }}</div>
    <div class="label">
      <div
        v-for="(item, idx) in formLabel"
        :key="idx"
        style="margin-bottom: 5px; padding-right: 25px"
      >
        <span>*</span>{{ item.label }}
      </div>
    </div>
    <el-form :ref="formName" :model="form_update">
      <el-scrollbar
        style="
          height: 100%;
          padding-bottom: 20px;
          padding-right: 25px;
          overflow: hidden;
        "
        class="fixed-scroll"
      >
        <div v-for="(temp, index) in form_update.user_arr" :key="index">
          <template v-for="(ele, i) in formLabel">
            <el-form-item
              :key="i"
              :prop="`user_arr.${index}.${ele.key}`"
              :rules="rules_update[ele.key]"
            >
              <el-input
                v-if="ele.type == 'input'"
                v-model="temp[ele.key]"
              ></el-input>
              <el-select
                v-if="ele.type == 'select'"
                v-model="temp[ele.key]"
                clearable
                filterable
              >
                <el-option
                  v-for="opt in ele.options"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </div>
      </el-scrollbar>
    </el-form>
    <div class="addbtn" @click="add_form_item">
      <img
        src="@/assets/img/addbtn.png"
        :alt="$t('personal.userManagement.newUserBtn')"
      />
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="closeDialog" size="small">{{
        $t("btn.cancelBtn")
      }}</el-button>
      <el-button size="small" class="confirm" type="primary" @click="save">{{
        $t("btn.saveBtn")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "560px",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {},
    },
    formLabel: {
      type: Array,
      default: () => [],
    },
    rules: {
      type: Object,
      default: () => {},
    },
    formName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form_update: this.form,
      rules_update: this.rules,
      submiting: false,
    };
  },
  computed: {
    dialog_visible() {
      return this.visible;
    },
    new_users() {
      return this.form_update.user_arr.filter((item) => {
        return (
          item.nickname != "" ||
          item.email != "" ||
          (item.roleId != null && item.roleId != "")
        );
      });
    },
  },
  mounted() {},

  methods: {
    closeDialog() {
      this.$refs["addUserForm"].resetFields();
      this.form_update.user_arr = [];
      this.add_form_item();
      this.$emit("close", false);
    },
    add_form_item() {
      let obj = {
        nickname: "",
        email: "",
        roleId: null,
        managerEmail: this.$store.getters.email,
      };
      this.form_update.user_arr.push(obj);
    },
    save() {
      this.submiting = true;
      if (this.new_users.length < 1) {
        this.$message.warning(this.$t("addAddUser.message"));
        this.submiting = false;
        return;
      }
      if (this.form_update.user_arr.length > this.new_users.length) {
        this.form_update.user_arr = this.new_users;
      }
      this.$nextTick(() => {
        this.submit();
      });
    },
    submit() {
      this.$refs[this.formName].validate((valid) => {
        if (valid) {
          this.$emit("submit", this.new_users);
        } else {
          this.submiting = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  text-align: left;
  font-size: 14px;
  color: #777;
}
.label {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;

  div {
    text-align: center;
    flex-grow: 1;
    span {
      color: red;
    }
  }
}
.fixed-scroll {
  ::v-deep .el-scrollbar__wrap {
    overflow: hidden;
  }
}

.add_user {
  .el-form {
    .el-scrollbar__view {
      div {
        display: flex;
        justify-content: space-between;
        gap: 5px;
      }
    }
  }
}
.addbtn {
  text-align: left;
  margin: 10px 0 10px 0;
}
.dialog-footer {
  text-align: right;
  margin-bottom: 20px;
}
</style>
