<template>
  <div>
    <div v-if="visible" class="dialog-title">
      <p style="margin-top: 10px">
        {{ $t("personal.userManagement.newUserForm.info2") }}
        <a :href="$t('importUserTemplate')" class="import">{{
          $t("personal.userManagement.newUserForm.info8")
        }}</a>
      </p>
      <div class="dialog-footer">
        <el-upload
          action="#"
          :http-request="uploadExcel"
          :show-file-list="false"
        >
          <el-button size="small" type="info" @click="close">{{
            $t("btn.cancelBtn")
          }}</el-button>
          <el-button class="btn-primary" size="small" type="primary">{{
            $t("btn.uploadBtn")
          }}</el-button>
        </el-upload>
      </div>
    </div>
    <div v-if="isUploadFinished">
      <div class="user-list-valid">
        <el-table :data="tableData" stripe>
          <el-table-column
            prop="nickname"
            :label="$t('personal.userManagement.table.name')"
            width="120"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="email"
            :label="$t('personal.userManagement.table.email')"
            width="180"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            :label="$t('personal.userManagement.table.role')"
            width="120"
          >
            <template slot-scope="scope">
              <span>{{ getRoleName(scope.row.roleId) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="basic_license_info.licenseType === 'MIXED_EDITION'"
            :label="$t('personal.userManagement.newUserForm.userType')"
            prop="userLicenseType"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.userLicenseType === "FIXED"
                  ? $t("certificate.ENTERPRISE_EDITION")
                  : $t("certificate.FLOAT_EDITION")
              }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('personal.userManagement.newUserForm.legal')"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <svg
                v-if="scope.row.isValid"
                xmlns="http://www.w3.org/2000/svg"
                class="icon-right"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="icon-error"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('personal.userManagement.newUserForm.illegal')"
            prop="errorMessageList"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <p
                v-for="(item, index) in scope.row.errorMessageList"
                :key="index"
              >
                {{ item }}
              </p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-footer">
        <el-button size="small" type="info" @click="close">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button class="btn-primary" size="small" @click="importUser">{{
          $t("btn.importBtn")
        }}</el-button>
      </div>
    </div>
    <el-dialog
      class="dialog dialog-title"
      :title="$t('file.table.options.deleteForm.title')"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="isImportFinished"
      width="25%"
      append-to-body
    >
      <p v-if="newUserList.length > 0 && invalidUserList.length > 0">
        {{ $t("personal.userManagement.add.p1") }}{{ newUserList.length
        }}{{ $t("personal.userManagement.add.p2") }}
      </p>
      <p v-if="newUserList.length > 0 && invalidUserList.length == 0">
        {{ $t("personal.userManagement.add.p1") }}{{ newUserList.length
        }}{{ $t("personal.userManagement.add.p3") }}
      </p>
      <p v-if="newUserList.length === 0 && invalidUserList.length > 0">
        {{ $t("personal.userManagement.add.p4") }}
      </p>
      <p v-if="newUserList.length === 0 && invalidUserList.length === 0">
        {{ $t("personal.userManagement.add.p5") }}
      </p>
      <p v-if="newUserList.length > 0" style="font-weight: 600">
        {{ $t("personal.userManagement.add.p6") }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="isImportFinished = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          v-if="newUserList.length > 0"
          class="btn-primary"
          @click="submit"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { importUserMultiple } from "@/network/user/index.js";
import { get_roles } from "@/network/addUser/index.js";
import { get_basic_license } from "@/network/license/index.js";
export default {
  name: "ImportMultipleUser",
  data() {
    return {
      visible: true,
      isUploadFinished: false,
      importVisible: false,
      isImportFinished: false,
      tableData: [],
      roleList: [],
      newUserList: [],
      invalidUserList: [],
      basic_license_info: {},
    };
  },
  async created() {
    const roleList = await get_roles();
    get_basic_license().then((res) => {
      this.basic_license_info = res;
    });
    this.roleList = roleList;
  },
  methods: {
    open() {
      this.isUploadFinished = false;
      this.visible = true;
      this.tableData = [];
    },
    async uploadExcel(e) {
      const file = new FormData();
      file.append("uploadFile", e.file);
      const results = await importUserMultiple(file);
      results.newUserList.forEach((user) => {
        user.isValid = true;
        this.tableData.push(user);
      });
      this.newUserList = results.newUserList;
      this.invalidUserList = results.invalidNewUserList;
      results.invalidNewUserList.forEach((user) => {
        user.isValid = false;
        this.tableData.push(user);
      });
      this.isUploadFinished = true;
      this.visible = false;
      this.$emit("setWidth", "46%");
    },
    getRoleName(id) {
      const role = this.roleList.filter((role) => role.roleId === id)[0];
      return role ? role.description : "";
    },
    importUser() {
      this.isImportFinished = true;
    },
    async submit() {
      await this.$emit("submit", this.newUserList);
      this.isImportFinished = false;
      this.importVisible = false;
    },
    close() {
      this.$emit("close", false);
      this.tempClose();
    },
    tempClose() {
      this.importVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.user-list-valid {
}
.icon-right {
  color: #37b24d;
}
.icon-error {
  color: #f03e3e;
}
.upload {
  padding: 10px 20px;
}
.upload-area {
}
.dialog-title {
  p {
    text-align: left;

    .import:link,
    .import:visited {
      color: #1c6ca1;
    }
  }
}
.dialog-footer {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
