var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-edit-dialog"},[_c('el-dialog',{staticClass:"user-edit-dialog",attrs:{"visible":_vm.visible,"title":_vm.$t('personal.userManagement.table.options.editUserInfoForm.title'),"close-on-click-modal":false,"show-close":true,"width":"25%"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.handleClose}},[_c('div',[_c('el-form',{attrs:{"label-position":"top"}},[_c('el-row',[_c('el-form-item',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t(
                'personal.userManagement.table.options.editUserInfoForm.name'
              )}},[_c('el-input',{staticStyle:{"width":"100%"},model:{value:(_vm.userInfo.nickname),callback:function ($$v) {_vm.$set(_vm.userInfo, "nickname", $$v)},expression:"userInfo.nickname"}})],1)],1),_c('el-row',[_c('el-form-item',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t(
                'personal.userManagement.table.options.editUserInfoForm.email'
              )}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"disabled":""},model:{value:(_vm.userInfo.email),callback:function ($$v) {_vm.$set(_vm.userInfo, "email", $$v)},expression:"userInfo.email"}})],1)],1),_c('el-row',[_c('el-form-item',{staticStyle:{"width":"100%"},attrs:{"label":_vm.$t(
                'personal.userManagement.table.options.editUserInfoForm.role'
              )}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value-key":"roleId"},on:{"change":_vm.handleRoleSelect},model:{value:(_vm.userInfo.roles[0]),callback:function ($$v) {_vm.$set(_vm.userInfo.roles, 0, $$v)},expression:"userInfo.roles[0]"}},_vm._l((_vm.rolesList),function(item){return _c('el-option',{key:item.roleId,attrs:{"label":item.description,"value":item}})}),1)],1)],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"plain"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(_vm._s(_vm.$t("btn.cancelBtn")))]),_c('el-button',{staticClass:"confirm",attrs:{"type":"primary"},on:{"click":_vm.editSave}},[_vm._v(_vm._s(_vm.$t("btn.saveBtn")))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }