<template>
  <div class="user-edit-dialog">
    <!-- 管理员编辑用户资料 -->
    <el-dialog
      :visible.sync="visible"
      :title="
        $t('personal.userManagement.table.options.editUserInfoForm.title')
      "
      :close-on-click-modal="false"
      :show-close="true"
      class="user-edit-dialog"
      width="25%"
      @close="handleClose"
    >
      <div>
        <el-form label-position="top">
          <el-row>
            <el-form-item
              :label="
                $t(
                  'personal.userManagement.table.options.editUserInfoForm.name'
                )
              "
              style="width: 100%"
            >
              <el-input
                v-model="userInfo.nickname"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              :label="
                $t(
                  'personal.userManagement.table.options.editUserInfoForm.email'
                )
              "
              style="width: 100%"
            >
              <el-input
                v-model="userInfo.email"
                style="width: 100%"
                disabled
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item
              style="width: 100%"
              :label="
                $t(
                  'personal.userManagement.table.options.editUserInfoForm.role'
                )
              "
            >
              <el-select
                v-model="userInfo.roles[0]"
                value-key="roleId"
                style="width: 100%"
                @change="handleRoleSelect"
              >
                <el-option
                  v-for="item in rolesList"
                  :key="item.roleId"
                  :label="item.description"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-row>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="plain" @click="visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button class="confirm" type="primary" @click="editSave">{{
          $t("btn.saveBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_roles } from "@/network/addUser/index.js";
import { update_user_info } from "@/network/user/index.js";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userInfo: {
        roles: [],
      },
      rolesList: [],
    };
  },
  watch: {
    user(newValue, oldValue) {
      this.userInfo = JSON.parse(JSON.stringify(this.user));
      //console.log(this.userInfo);
    },
  },
  mounted() {
    get_roles()
      .then((res) => {
        this.rolesList = res;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    editSave() {
      update_user_info({
        avatar: this.userInfo.avatar || "",
        email: this.userInfo.email,
        nickname: this.userInfo.nickname,
        roleId: this.userInfo.roles.map((item) => {
          return item.roleId;
        }),
      }).then((res) => {
        if (
          this.userInfo.accountId === this.$store.getters.user_info.accountId
        ) {
          this.$store.commit("SET_USERINFO", this.userInfo);
        }
        this.$emit("update:visible", false);
        this.$message.success(this.$t("chart.message.saveSuccess"));
        this.$emit("change", this.userInfo);
      });
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleRoleSelect(value) {},
  },
};
</script>

<style lang="scss" scoped>
.user-edit-dialog {
  .el-dialog__wrapper {
    ::v-deep .el-dialog {
      .el-dialog__header {
        text-align: left;
        .el-dialog__title {
        }
        .el-dialog__headerbtn {
          font-size: 20px;
          top: 15px;
          right: 41px;
          i {
            font-weight: 700;
          }
        }
      }
      .el-dialog__body {
        text-align: left;
        .tip {
          color: rgba(166, 166, 166, 1);
          font-size: 14px;
        }
        .label {
          display: flex;
          margin: 16px 0 7px;
          div {
            flex: 1;
            text-align: center;
            font-size: 16px;
            span {
              color: red;
            }
          }
        }
        .el-form {
          color: rgba(56, 56, 56, 1);
          margin-top: 10px;
          .el-form-item:nth-child(1),
          .el-form-item:nth-child(2) {
            margin-right: 21px;
          }
          .el-form-item {
            display: inline-block;
            margin-bottom: 12px;
            .el-input {
              .el-input__inner {
              }
              .el-input__icon {
              }
            }
            .el-form-item__label {
              padding-bottom: 0px;
              line-height: 24px;
            }
          }
        }
      }
      .el-dialog__footer {
        padding: 20px 41px 20px;
        .confirm {
          background: #30648f;
          border: 1px solid #30648f;
        }
      }
    }
  }
}
</style>
