<template>
  <div class="user_management">
    <el-row type="flex" justify="space-between">
      <el-input
        v-model="search_name"
        class="search_input"
        size="small"
        :placeholder="$t('placeholder.input')"
        prefix-icon="el-icon-search"
        @input="searchName"
      >
      </el-input>
      <div>
        <el-button
          class="add-button"
          icon="el-icon-plus"
          type="primary"
          @click="add_customer_dialog"
        >
          {{ $t("personal.userManagement.newUserBtn") }}
        </el-button>
      </div>
    </el-row>
    <el-dialog
      :visible="tabVisible"
      class="dialog"
      :width="width"
      :close-on-click-modal="false"
      :show-close="true"
      @close="closeDialog"
    >
      <div slot="title"></div>
      <el-tabs v-model="activeName" class="tab" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('personal.userManagement.newUserForm.tab1')"
          name="normalImport"
        >
          <add-user
            v-if="tabVisible"
            ref="addUserDialog"
            :form-name="'addUserForm'"
            :form="form"
            :form-label="formLabel"
            :rules="rules"
            @submit="submit"
            @close="closeDialog"
          ></add-user>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('personal.userManagement.newUserForm.tab2')"
          name="excelImport"
        >
          <import-multiple-user
            ref="importMultipleUserDialog"
            @setWidth="setWidth"
            @submit="submit"
            @close="closeDialog"
          ></import-multiple-user>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-row>
      <user-manage-table
        ref="usertable"
        :basic-license-info="basic_license_info"
      ></user-manage-table>
    </el-row>
  </div>
</template>

<script>
import { add_user, email_exist, get_roles } from "@/network/addUser/index.js";
import UserManageTable from "./components/UserManageTable.vue";
import AddUser from "./components/AddUser";
import ImportMultipleUser from "./components/ImportMultipleUser";
import { get_basic_license } from "@/network/license/index.js";
export default {
  inject: ["reload"],
  components: {
    UserManageTable,
    AddUser,
    ImportMultipleUser,
  },
  data() {
    let checkEmail = (rule, value, callback) => {
      const mailReg =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!value) {
        return callback(
          new Error(this.$t("personal.userManagement.newUserForm.info3"))
        );
      }
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(
            new Error(this.$t("personal.userManagement.newUserForm.info4"))
          );
        } else {
          email_exist(value)
            .then((res) => {
              res.pass ? callback() : callback(new Error(res.detail));
            })
            .catch((err) => console.log(err));
        }
      }, 100);
    };
    return {
      width: "40%",
      tabVisible: false,
      activeName: "normalImport",
      search_name: "",
      dialog_visible: false,
      basic_license_info: {},
      formLabel: [
        {
          label: this.$t("personal.userManagement.newUserForm.name"),
          key: "nickname",
          type: "input",
        },
        {
          label: this.$t("personal.userManagement.newUserForm.email"),
          key: "email",
          type: "input",
        },
        {
          label: this.$t("personal.userManagement.newUserForm.role"),
          key: "roleId",
          type: "select",
          options: [],
        },
      ],
      form: {
        user_arr: [
          {
            nickname: "",
            email: "",
            roleId: null,
            managerEmail: this.$store.getters.email,
          },
        ],
      },
      rules: {
        nickname: [
          {
            required: true,
            message: this.$t("personal.userManagement.newUserForm.info5"),
            trigger: "blur",
          },
        ],
        email: [
          {
            trigger: ["blur", "change"],
            validator: checkEmail,
          },
        ],
        roleId: [
          {
            required: true,
            message: this.$t("personal.userManagement.newUserForm.info6"),
            trigger: "change",
          },
        ],
        userLicenseType: [
          {
            required: true,
            message: this.$t("personal.userManagement.newUserForm.info9"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    new_users() {
      return this.form.user_arr.filter((item) => {
        return (
          item.nickname != "" ||
          item.email != "" ||
          (item.roleId != null && item.roleId != "")
        );
      });
    },
  },
  mounted() {
    get_basic_license().then((res) => {
      this.basic_license_info = res;
      if (this.basic_license_info.licenseType === "MIXED_EDITION") {
        this.formLabel.push({
          label: this.$t("personal.userManagement.newUserForm.userType"),
          key: "userLicenseType",
          type: "select",
          options: [
            {
              label: this.$t("certificate.ENTERPRISE_EDITION"),
              value: "FIXED",
            },
            {
              label: this.$t("certificate.FLOAT_EDITION"),
              value: "FLOATING",
            },
          ],
        });
      }
    });
    get_roles()
      .then((res) => {
        res.map((item) => {
          // this.options.push({ value: item.roleId, label: item.description });
          this.formLabel.forEach((ele) => {
            if (ele.type == "select" && ele.key == "roleId") {
              ele.options.push({ value: item.roleId, label: item.description });
            }
          });
        });
      })
      .catch((err) => console.log(err));
  },
  methods: {
    setWidth(width) {
      this.width = width;
    },
    handleClick(item) {
      if (item.index === "1") {
        this.$refs.importMultipleUserDialog.open();
      } else {
        this.$refs.importMultipleUserDialog.tempClose();
      }
    },
    importMultipleUser() {
      this.$refs.importMultipleUserDialog.open();
    },
    closeDialog(val) {
      this.activeName = "normalImport";
      this.tabVisible = val;
    },
    add_customer_dialog() {
      this.tabVisible = true;
    },
    searchName(name) {
      this.$refs.usertable.searchNameFn(name);
    },
    submit(new_users) {
      add_user(new_users)
        .then((res) => {
          if (!res) {
            this.$refs.addUserDialog.submiting = false;
            this.dialog_visible = false;
            this.$message.success(
              this.$t("personal.userManagement.newUserForm.info7")
            );
            this.reload();
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  margin-right: 10px;
  font-size: 15px;
}
.dialog {
  ::v-deep .el-dialog__header {
    padding: 8px 0 !important;
  }
}
</style>
