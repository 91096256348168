<template>
  <div class="user-reset-dialog">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="true"
      class="user-reset-dialog"
      :title="$t('btn.ResetPassword')"
      width="25%"
      @close="handleClose"
    >
      <el-form
        label-position="left"
        label-width="130px"
        :rules="rules"
        :model="userInfo"
      >
        <el-form-item :label="$t('user.ChangePassword')" prop="password"
          ><el-input
            v-model="userInfo.password"
            :placeholder="$t('user.PleaseEnterYourPassword')"
            type="password"
          ></el-input
        ></el-form-item>

        <el-form-item :label="$t('user.ConfirmPassword')" prop="confirmPassword"
          ><el-input
            v-model="userInfo.confirmPassword"
            :placeholder="$t('user.ConfirmPassword')"
            type="password"
          ></el-input
        ></el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="footer-button" @click="visible = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button class="footer-button" type="primary" @click="submitForm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from "element-ui";
import { reset_user_password } from "@/network/user/index.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    let validatePass1 = (rule, value, callback) => {
      if (value === "") {
        (this.validatePass_1 = false),
          callback(new Error(this.$t("personalAside.checkPsw")));
      } else {
        const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$/g;
        if (regExp.test(value)) {
          // 验证成功
          (this.validatePass_1 = true), callback();
        } else {
          callback(new Error(this.$t("personalAside.checkPsw")));
        }
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        (this.validatePass_2 = false),
          callback(new Error(this.$t("user.PleaseEnterYourPasswordAgain")));
      } else if (value !== this.userInfo.password) {
        (this.validatePass_2 = false),
          callback(new Error(this.$t("user.passwordDoesNotMatch")));
      } else {
        (this.validatePass_2 = true), callback();
      }
    };
    return {
      // 储存密码和确认密码
      userInfo: {
        password: "",
        confirmPassword: "",
      },
      // 密码以及确认密码通过的标志
      validatePass_1: false,
      validatePass_2: false,
      // 校验规则
      rules: {
        password: [
          {
            required: true,
            trigger: "blur",
            validator: validatePass1,
          },
        ],
        confirmPassword: [
          {
            required: true,
            trigger: "blur",
            validator: validatePass2,
          },
        ],
      },
    };
  },

  methods: {
    // 弹窗关闭触发
    handleClose() {
      // 同步更新父级弹窗关闭
      this.$emit("update:visible", false);
    },
    // 确认重置密码
    async submitForm() {
      // 通过检验
      if (this.validatePass_1 && this.validatePass_2) {
        // 修改后端用户密码数据
        reset_user_password({
          accountId: this.user.accountId,
          password: this.userInfo.password,
        });
        Message({
          message: this.$t("user.ResetPasswordSuccessfully"),
          type: "success",
          duration: 3 * 1000,
        });

        this.$emit("update:visible", false);
      } else {
        Message({
          message: this.$t("user.ErrorInInput"),
          type: "error",
          duration: 3 * 1000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-reset-dialog {
  .el-dialog__header {
    border-radius: 4%;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    font-size: 0.0833rem;
    // padding: 12px 0;
    .footer-button {
      padding: 0.0313rem 0.0521rem;
    }
  }
}
</style>
